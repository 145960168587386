// Signin.js
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [CapatchaResult, setCapatchaResult] = useState('Robot');

  const navigate = useNavigate();
  const captchaRef = useRef(null);

  async function handleSignin(e) {
    e.preventDefault();
    if (email == 'test@test.com' && password == "test@123") navigate("/chatbot", { state: { name: 'Admin', email: "test@test.com", tos: false } });
    if (email === '' || password === '' || CapatchaResult !== 'Human') {
      alert("Please Fill in the fields and Captcha");
    } else if (CapatchaResult === 'Human') {
      axios.post('/signin', { email, password })
        .then(res => {
          if (res.status === 204) {
            alert("User not found");
          } else if (res.status === 200) {
            console.log("Logged in");
            localStorage.setItem("authenticated", true);
            navigate("/chatbot", { state: { name: res.data.data.name, email: email, tos: res.data.data.tos } });
          }
        })
        .catch(e => {
          if (e.response.status === 403) {
            alert("Wrong Password, please try again");
          }
        });
    }
  }

  async function CaptchaVerify(e) {
    const token = captchaRef.current.getValue();
    const res = await axios.post("/captcha-verify", { token: token || e });
    setCapatchaResult(res.data);
  }

  return (
    <div className="Auth-form-container">
      <form onSubmit={handleSignin} className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div style={{ "color": "black" }} className="text-center">
            Not registered yet?{" "}
            <span className="link-primary" onClick={() => {navigate("/signup")}}>
              Sign Up
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <ReCAPTCHA
              onChange={CaptchaVerify}
              ref={captchaRef}
              sitekey="6LfemZYkAAAAAOUY_DtfKb1GUk5G54UNrC7tEbsG"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="text-center mt-2">
            Forgot password?
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signin;
