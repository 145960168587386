import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import axios from 'axios';


const AudioRecord = ({GetAudio}) => {
  const recorderControls = useAudioRecorder()
  const addAudioElement = (blob) => {
    
    GetAudio(blob, async (transcription) => {
      const text = document.createElement("p");
      text.innerText = transcription.text;
      document.body.appendChild(text);


      const formData = new FormData();
      formData.append('text', transcription.text);
      
      const response = await axios.post('/speech', {text: transcription.text}, {responseType: "blob"});
      const blobb = response.data;
      // console.log(blobb);
      const url = URL.createObjectURL(blobb);
      const audio = document.createElement("audio");
      audio.src = url;
      audio.controls = true;
      document.body.appendChild(audio);
    })


    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

  return (
    <div>
      <AudioRecorder 
        
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
      />
      <button onClick={recorderControls.stopRecording}>Stop recording</button>
    </div>
  )
}

export default AudioRecord;