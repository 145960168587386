import React from 'react'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import "./App.css"
// import Home from './components/Home'
// import Login from './components/login.component'
import Signup from './components/Signup'
import ChatBody from './components/ChatBody'
import Home from "./components/Home"
import About from './components/about'
import Terms from './components/Terms'
import Signin from './components/Signin'
import VoiceChat from './VoiceChat'
function App() {
  return (

    <>
      

      <Router>
      
        <Routes>
          <Route exact path="/"  element={ <Home />} />
          <Route exact path="/about"  element={ <About />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route path="/chatbot" element={<ChatBody/>} />
          <Route path="/chatbot/voicechat" element={< VoiceChat />} />
          <Route path="/terms" element={<Terms/>} />
          {/* <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} /> */}
        </Routes>
      </Router>

    </>
  )
}

export default App
