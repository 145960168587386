import React from 'react'
import AudioRecord from './components/AudioRecord'

const VoiceChat = () => {
  const getResponse = async (audioBlob, callback) => {
    // console.log(audioBlob);
    const formData = new FormData();
    formData.append('file', audioBlob); // 'file' should match here
    // Log FormData entries
    // formData.forEach((value, key) => {
    //   console.log(`${key}:`, value);
    // });
    const transcription = await fetch('/transcription', {
      headers: { Accept: "application/json" },
      method: 'POST',
      body: formData,
    })

    console.log(transcription);
    callback(await transcription.json());

  }


  return (
    <>

      <AudioRecord GetAudio={getResponse} />


    </>
  )
}

export default VoiceChat